/* --------------------------------------------------------------------
Import accessibility helpers
--------------------------------------------------------------------*/
import { toggleAriaExpanded, addTabIndex, removeTabIndex  } from './accessibility.js';
import { hideHeader, showHeader, showBackToTop  } from './tools.js';


/* --------------------------------------------------------------------
Handle Burger Menu
--------------------------------------------------------------------*/
const bodyTag   = document.getElementsByTagName("body");
const mainTag   = document.getElementById("main-content");
const burger    = document.getElementsByClassName('hamburger');
const navMenu   = document.querySelector('.main-nav');
const navMenuLinks  = document.querySelectorAll('.main-link');
const tabs      = document.querySelectorAll('.tab-block');

let navMenuIsAnimating = false;

let isMenuOpen = false;

if( window.outerWidth > 1023 ){
    addTabIndex('has-children');
}
else{
    removeTabIndex('has-children');
}


mainTag.addEventListener("click", function() {
    if(isMenuOpen){
        if(burger.length !== 0){
            burger.click();
        }
    }

}, false);

if(burger.length !== 0){
    burger[0].addEventListener("click", function() {
        if(navMenuIsAnimating){
            return true;
        }
        else{
            const burgerBtn = this;

            toggleAriaExpanded( burgerBtn );

             navMenu.classList.remove(
                'w-0',
                'hidden'
            );

            window.setTimeout(function(){
                isMenuOpen = (isMenuOpen == false) ? true : false;

                navMenu.classList.add('duration-700');
                bodyTag[0].classList.toggle("overflow-hidden");
                bodyTag[0].classList.toggle("nav-open");
                burgerBtn.classList.toggle("is-active");

                if(isMenuOpen){
                    navMenu.scrollTop = 0;
                    navMenu.classList.remove(
                        '-translate-y-full',
                        'w-0',
                        'hidden'
                    );

                    if(tabs[0] !== undefined){
                        tabs[0].classList.add(
                            'invisible',
                            'pointer-events-none',
                        );
                    }

                    removeTabIndex( 'main-link' );
                }
                else{
                    navMenu.classList.add('-translate-y-full');
                    addTabIndex( 'main-link' );

                    if(tabs[0] !== undefined){
                        tabs[0].classList.remove(
                            'invisible',
                            'pointer-events-none'
                        );
                    }
                }
            }, 5);
        }

    }, false);

    if (navMenuLinks.length !== 0) {
        for (var i = navMenuLinks.length - 1; i >= 0; i--) {
            navMenuLinks[i].addEventListener("click", function() {

                if(burger.length !== 0){
                    burger[0].click();
                }

            }, false);
        };
    }


    /* ------------------------------------------------------------
    List for key board input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(!global.searchOpen){
            if (e.key === "Escape") {
                burger[0].classList.remove("is-active");
                bodyTag[0].classList.remove("overflow-hidden");
                bodyTag[0].classList.toggle("nav-open");
                navMenu.classList.add(
                    'duration-700',
                    '-translate-y-full'
                );

                addTabIndex( 'main-link' );
                isMenuOpen = false;
            }
        }
    });

    /* ------------------------------------------------------------
    List for transition end on navigation menu, when complete
    remove transition duration.
    ------------------------------------------------------------ */
    navMenu.ontransitionrun = () => {
        navMenuIsAnimating = true;
    };

    navMenu.ontransitionend = () => {
        navMenuIsAnimating = false;
        // navMenu.classList.remove('duration-700');

        if(!isMenuOpen){
            // navMenu.classList.add(
            //     'w-0',
            //     'hidden'
            // );
        }
    };
}

/* --------------------------------------------------------------------
Expand / Collapse main navigation sub pages
--------------------------------------------------------------------*/
const expandableBtn = document.querySelectorAll('.has-children');

if(expandableBtn){

    expandableBtn.forEach(function( button ){
        button.onclick = function(){
            button.classList.toggle('has-children');

            if( button.children[1].classList.contains('rotate-90') ){
                button.children[1].classList.remove('rotate-90');
                button.children[1].classList.add('-rotate-90');
            }
            else{
                button.children[1].classList.remove('-rotate-90');
                button.children[1].classList.add('rotate-90');
            }

            if(button.nextElementSibling){
                button.nextElementSibling.classList.toggle('hidden');
            }
        };
    });
}