import { hideHeader, showHeader, hideBackToTop, showBackToTop, slideDownBackToTop, slideUpBackToTop  } from './tools.js';

/* --------------------------------------------------------------------
Hide header bar on scroll.
Reveal after period of inactivity.
-------------------------------------------------------------------- */
let hideHeaderTriggerPoint = 100;
var isScrolling, isWaiting, lastScrollPos;

window.addEventListener('scroll', function(){
    // Clear our timeout throughout the scroll
    window.clearTimeout( isScrolling );
    window.clearTimeout( isWaiting )


    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function() {
        // Run the callback

        // Wait 20 seconds, if still in active, show header again.
        isWaiting = setTimeout(function() {
            showHeader();
        }, 10000);

    }, 66);

    let ftr = document.getElementById('ftr');
    let pos = (window.innerHeight + Math.round(window.scrollY) );

    // console.log( ftr.offsetHeight );
    // console.log( pos, (document.body.offsetHeight - ftr.offsetHeight) );

    if( pos  >= document.body.offsetHeight - ftr.offsetHeight ) {
        slideUpBackToTop();
    }
    else{
        slideDownBackToTop();
    }

    // Back To Top
    if(getYPosition() > hideHeaderTriggerPoint){
        showBackToTop();
    }
    else{
        hideBackToTop();
    }

    lastScrollPos = getYPosition();
});

function getYPosition(){
    var top  = window.pageYOffset || document.documentElement.scrollTop
    return top;
}